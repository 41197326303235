@import "./variables.scss";

.dashboard_modal.show.edit_ceremony .modal-dialog .modal-content .modal-body {
	width: 100%;
	padding: 1rem 1.5rem;
	form.edit_event_ceremony_form {
		width: 100%;
		.button_group {
			justify-content: flex-end;
			margin-top: 20px;
			gap: 30px;
			button {
				height: 35px;
				font-family: "Inter", sans-serif;
				font-size: 13px;
				font-weight: 400;
				line-height: 18.2px;
				letter-spacing: -0.025em;
				text-align: left;
				&:first-child {
					border: 1px solid #5455660d;
					padding: 0px 12px;
				}
				&:last-child {
					color: white;
				}
			}
		}
		.form_element.input.input_dropdown {
			width: 100%;
		}
		.select_date_input_view .select_date_input button {
			display: none;
		}
		.ceremony_input_group {
			display: flex;
			gap: 10px;
			width: 100%;
			.new_ceremony_picker {
				width: 100%;
				margin-bottom: 0;
				label {
					top: 4px;
				}
			}
			.new_ceremony_picker > div {
				margin-bottom: 0 !important;
			}
		}
		.custom_input_view,
		.select_date_input_view {
			width: 140px;
			min-width: 140px;
			max-width: 140px;
		}
		.form_element.input2 input:focus {
			border: 1px solid #000;
		}
	}
}

.event_overview_section {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 40px;
	.loading_view {
		height: 70vh;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: auto;
	}
	h1 {
		margin-bottom: 0;
	}

	.all_events {
		display: flex;
		flex-direction: column;
		width: 100%;
		background-color: #fafafc;
		border-radius: 6px;
		gap: 10px;

		.all_events_row {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 12px 16px;
			.title_row {
				gap: 10px;
				h3 {
					font-family: "Inter", sans-serif;
					font-size: 14px;
					font-weight: 500;
					line-height: 19.6px;
					letter-spacing: -0.025em;
					text-align: left;
					color: #000;
					margin-bottom: 0;
				}
			}
			.controls {
				display: flex;
				gap: 10px;
				align-items: center;

				button,
				a {
					background-color: #fff;
					padding: 0px 10px;
					height: 32px;
					border: 1px solid #5455660d;
					font-family: "Inter", sans-serif;
					font-size: 12px;
					font-weight: 500;
					line-height: 18px;
					letter-spacing: -0.025em;
					text-align: left;
					border-radius: 6px;
					color: #404040;
					display: flex;
					align-items: center;
					justify-content: center;
					text-decoration: none;
					&.create_event_btn {
						background-color: #031729;
						color: white;
						padding: 0px 10px;
						border-radius: 6px;
						height: 32px;
						font-family: "Inter", sans-serif;
						font-size: 12px;
						border: none;
						font-weight: 500;
						line-height: 18px;
						letter-spacing: -0.025em;
						text-align: left;
						align-items: center;
						display: flex;
						border: 0;
						gap: 5px;
						svg {
							height: 14px;
							width: 14px;
							stroke: white;
						}
					}
				}
				a {
					span {
						background: #fafafc;
						font-size: 10px;
						font-weight: 500;
						line-height: 10px;
						color: #404040;
						height: 18px;
						width: 18px;
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 18px;
					}
				}
				.dropdown {
					background-color: white;
					height: 32px;
					border-radius: 6px;
					border: 1px solid #5455660d;
					padding: 0px 12px;
					display: flex;
					align-items: center;
					button {
						font-family: "Inter", sans-serif;
						font-size: 12px;
						font-weight: 500;
						line-height: 18px;
						letter-spacing: -0.025em;
						text-align: left;
						color: #606060;
						span {
							svg {
								height: 10px;
								width: 10px;
							}
						}
					}
				}
			}
			.list_grid_button {
				display: flex;
				align-items: center;
				gap: 16px;
				background-color: white;
				gap: 0;
				border: 1px solid #5455660d;
				border-radius: 6px;
				height: 32px;
				button {
					border: 0;
					background-color: white;
					svg {
						height: 14px;
						width: 14px;
						color: #606060;
					}
					&:first-child {
						background-color: #fbfbfb;
					}
				}
			}
		}
		.events_grid {
			display: grid;
			grid-template-columns: repeat(3, 280px);
			gap: 25px;
			background-color: #fff;
			padding: 24px;
			margin: 0px 6px 6px;
			height: 512px;
			border-radius: 6px;

			.event_card_wrapper {
				position: relative;
				#dropdown {
					background-color: white;
					height: 32px;
					width: 32px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 8px;
					padding: 6px;
					box-shadow: 0px 5.33px 26.67px 0px #0000000d;
					position: absolute;
					right: 10px;
					top: 25px;
					z-index: 10;
					button {
						&.dropdown_toggle {
							padding: 0px !important;
							height: 32px;
							width: 32px;
							display: flex;
							align-items: center;
							box-shadow: none;
						}
						span {
							display: none;
							svg {
								stroke: #303030;
								display: flex;
								align-items: center;
								justify-content: center;
								margin: 0;
								padding: 0;
							}
						}
					}
				}
				.event_card {
					border: 1px solid #5455660d;
					border-radius: 6px;
					height: 164px;
					width: 280px;
					cursor: pointer;
					.card_header {
						height: 38px;
						width: 100%;
						padding: 10px 24px;
						border-top-left-radius: 6px;
						border-top-right-radius: 6px;
					}
					.card_content {
						display: flex;
						align-items: flex-start;
						flex-direction: column;
						padding: 0px 12px 10px;
						margin-top: -16px;
						position: relative;
						.card_row_1 {
							display: flex;
							align-items: flex-start;
							justify-content: space-between;
							width: 100%;
							img {
								height: 64px;
								width: 64px;
								border-radius: 6px;
								object-fit: cover;
								box-shadow: 0px 4px 20px 0px #0000000d;
							}
						}
						h4 {
							font-family: "Inter", sans-serif;
							font-size: 14px;
							font-weight: 400;
							line-height: 19.6px;
							letter-spacing: -0.025em;
							text-align: center;
							margin-top: 20px;
							color: #031729;
						}
						.card_row_2 {
							display: flex;
							align-items: flex-start;
							width: 100%;
							color: #9395aa;
							gap: 6px;
							div {
								display: flex;
								align-items: center;
								font-family: "Inter", sans-serif;
								font-size: 12px;
								font-weight: 400;
								line-height: 18px;
								letter-spacing: -0.025em;
								text-align: left;
								gap: 5px;
								svg {
									height: 12px;
									width: 12px;
									color: #d9d9d9;
								}
							}
							hr {
								width: 12px;
								color: #e9e9e9;
								height: 1px;
								margin: 0;
								transform: rotate(90deg);
								margin-top: 8px;
							}
						}
					}
				}
			}

			@media (max-width: 1024px) {
				display: flex;
				flex-wrap: wrap;
			}
		}

		.add_new_event_wrapper {
			background-color: white;
			margin: 20px 10px;
			.add_new_event {
				border: 0.5px solid #e72e50;
				box-shadow: 0px 4px 20px 0px #0000000d;
				box-shadow: 0px 14.4px 72px 0px #0000000d;
				border: 3px solid #ffffff;
				width: 480px;
				border: 0.5px solid #e72e50;
				padding: 25px 0;
				border-radius: 6px;
				background-color: white;
				margin: 100px auto 150px;
				height: 246px;
				.icon_wrapper {
					border: 1px solid #5455660d;
					border-radius: 2px;
					width: fit-content;
					margin: auto;
					height: 40px;
					width: 40px;
					border-radius: 2px;
					display: flex;
					align-items: center;
					svg {
						height: 20px;
						width: 20px;
						margin: 10px;
						display: flex;
						align-items: center;
						justify-content: center;
						margin: auto;
						stroke: $base_red;
					}
				}
				.text {
					border-radius: 8px;
					padding: 16px 12px;
					h4 {
						font-size: 1rem;
						text-align: center;
						color: #000;
						font-family: "Inter", sans-serif;
						font-size: 0.875rem;
						font-weight: 500;
						line-height: 36px;
						letter-spacing: -0.02em;
					}
					p {
						text-align: center;
						font-size: 1rem;
						font-size: 0.75rem;
						font-weight: 400;
						line-height: 16.8px;
						letter-spacing: -0.025em;
						text-align: center;
						color: #606060;
						span {
							font-weight: bold;
							color: black;
						}
					}
				}
				button {
					height: 36px;
					padding: 12px 12px 10px;
					border-radius: 6px;
					font-family: "Inter", sans-serif;
					font-size: 0.75rem;
					font-weight: 600;
					line-height: 12px;
					display: flex;
					align-items: center;
					margin: auto;
					text-align: center;
					background-color: #000;
					color: #fff;
					border: none;
					gap: 5px;
					svg {
						height: 16px;
						width: 16px;
					}
				}
			}
		}
	}
}

.create_new_event {
	.modal-dialog .modal-header {
		height: 48px;
		padding-top: 5px !important;
		position: sticky;
		top: 0;
		right: 0;
		width: 480px;
		z-index: 10;
		border-top-left-radius: 0px !important;
		border-top-right-radius: 0px !important;
	}
	.modal-title {
		font-size: 14px !important;
		font-weight: 500 !important;
	}
	hr {
		border-top: 1px solid #a291911a;
		margin: 0px;
	}
	.create_new_event_form {
		height: 100%;
		width: 100%;
		margin-top: 0px;
		margin-bottom: 400px;
		padding-top: 20px;
		.form_row {
			padding: 10px 24px 24px;
			.switch_group {
				display: flex;
				margin-top: 14px;
				align-items: center;
				width: 100%;
				justify-content: flex-end;
				padding-right: 24px;
				gap: 5px;
			}
		}
		.input_group {
			display: flex;
			align-items: flex-start;
			flex-direction: row;
			gap: 5px;
			position: relative;
			.upload_image {
				width: 32px;
				height: 32px;
				border-radius: 50%;
				background-color: #fafafc;
				padding: 10px;
				display: flex;
				cursor: pointer;
				align-items: center;
				justify-content: center;
				min-height: 32px;
				min-width: 32px;
				position: absolute;
				right: 15px;
				z-index: 1;
				bottom: 12px;
				input {
					position: absolute;
					width: 100%;
					height: 100%;
					z-index: 100;
					cursor: pointer;
					&::-webkit-file-upload-button {
						visibility: hidden;
					}
				}
				svg {
					min-height: 12px;
					min-width: 12px;
					position: relative;
					z-index: 1;
				}
				img {
					height: 32px;
					width: 32px;
					border-radius: 100%;
					min-height: 32px;
					min-width: 32px;
					object-fit: cover;
					object-position: top center;
				}
			}
			.custom_input_view {
				width: 100%;
			}
		}
		.section_title {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 10px;
			h6 {
				color: $base_red;
				font-weight: 600;
				margin: 0;
				padding: 0;
				font-size: 0.8125rem;
				font-family: "Inter", sans-serif;
			}
			div {
				display: flex;
				align-items: center;
				gap: 5px;
			}
			button {
				height: 32px;
				width: fit-content;
				margin: unset;
				padding: 7px 8px !important;
				background-color: #fbfbfb;
				color: #031729;
				font-family: "Inter", sans-serif;
				font-size: 0.75rem;
				font-weight: 500;
				background-color: none;
				line-height: 18px;
				letter-spacing: -0.025em;
				border-radius: 6px;
				text-align: center;
				border: none;

				svg {
					stroke: #031729;
					height: 12px;
					width: 12px;
				}
			}
		}
		.ceremonies {
			display: flex;
			flex-direction: column;
			gap: 15px;

			.ceremony_input {
				gap: 15px;
				display: flex;
				flex-direction: column;
				h6 {
					font-family: "Inter", sans-serif;
					font-size: 10px;
					font-weight: 400;
					line-height: 10px;
					letter-spacing: -0.025em;
					color: #9395aa;
					text-align: left;
					margin-bottom: -6px;
				}
				.input_group {
					flex-direction: row;
					display: flex;
					gap: 10px;
					.input.input_dropdown {
						width: 100%;
					}
				}
				.select_date_input_view {
					button {
						display: none;
					}
				}
			}

			.new_ceremony_picker {
				width: 100%;
				margin-bottom: 0;
			}
		}
		.collaborators_section {
			.add_collaborators {
				display: flex;
				flex-direction: column;
				gap: 10px;
				margin-top: 10px;
				.top_row {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 20px;
					.button_group {
						display: flex;
						align-items: center;
						gap: 10px;
						button {
							padding: 6px;
							border-radius: 4px;
							border: 0;
						}
						button:last-child {
							background-color: #031729;
							color: #fff;
							svg path {
								stroke: white;
							}
							&:hover {
								color: #fff;
							}
						}
						.permission button {
							background-color: #fbfbfb;
							color: #4285f4;
						}
						.invite_btn {
							position: relative;
							display: flex;
							align-items: center;
							gap: 5px;
							padding: 6px;
							border-radius: 4px;
							font-size: 12px;
							font-weight: 500;
							line-height: 18px;
							letter-spacing: -0.025em;
							text-align: left;

							span {
								position: absolute;
								left: 0;
							}
							&.loading {
								min-width: 120px;
							}
							svg {
								height: 14px;
								width: 14px;
							}
						}
					}

					.custom_input {
						display: flex;
						align-items: center;
						padding: 4px 6px;
						border-radius: 6px;
						width: 100%;
						height: 40px;
						input {
							text-wrap: nowrap;
							white-space: nowrap;
							font-family: "Inter", sans-serif;
							font-size: 12px;
							font-weight: 500;
							line-height: 18px;
							letter-spacing: -0.025em;
							color: #000;
							width: 100%;
							background-color: transparent;
							border: 0;
							height: 40px;
							padding-left: 3px;
							padding-right: 3px;
							margin-bottom: 0;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
						svg {
							height: 13px;
							width: 13px;
						}
						&:focus-within {
							border: 1px solid #dddfe380;
						}
					}
				}
				input {
					margin-bottom: 0;
				}
				.input_group {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					gap: 10px;
					.form_element {
						width: 100%;
						input.form-control {
							width: 95%;
						}
					}

					.add_role {
						width: 180px;
					}
				}
				.dropdown {
					button.dropdown_toggle {
						padding: 6px 10px !important;
						border-radius: 4px;
						display: flex;
						justify-content: space-between;
						color: #4285f4;
						font-size: 12px;
						font-weight: 500;
						background-color: #fbfbfb;
						min-height: 24px;
						text-wrap: nowrap;
						white-space: nowrap;
						svg {
							width: 12px;
							height: 12px;
							stroke: #4285f4 !important;
						}
						&:hover {
							background-color: #fbfbfb !important;
							color: #4285f4 !important;
							svg {
								color: unset;
							}
						}
					}
					.dropdown-menu.show {
						button {
							background-color: #fff;
							justify-content: flex-start;
							padding: 5px 10px !important;
							height: unset;
							&:hover {
								background-color: #fbfbfb;
							}
						}
					}
				}
				img {
					height: 280px;
					width: 280px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin: auto;
				}
				font-family: "Inter", sans-serif;
				.text_content {
					border: 1.11px solid #5455660d;
					padding: 16px 8px 0px;
					border-radius: 8px;
					margin-bottom: 12px;
					h4 {
						font-size: 24px;
						font-weight: 600;
						color: #031729;
						line-height: 36px;
					}
					.text_1 {
						margin-bottom: 20px;
						color: #031729;
					}
					p {
						font-weight: 400;
						color: $slate_gray;
						font-size: 12px;
						span {
							color: $primary;
						}
					}
					letter-spacing: -0.02em;
					text-align: center;
				}
			}
			.collaborators_list {
				display: flex;
				flex-direction: column;
				width: 100%;
				gap: 15px;

				.collaborator {
					display: flex;
					border: 1px solid #5455660d;
					padding: 6px 8px;
					border-radius: 6px;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					.action_group {
						display: flex;
						gap: 10px;
						button {
							padding: 6px;
							border-radius: 4px;
							color: $base_red;
							font-size: 10px;
							font-weight: 500;
							line-height: 10px;
							text-align: left;
							background-color: transparent;
							height: 22px;
							border: none;
						}
					}
					.collaborator_item {
						display: flex;
						align-items: center;
						justify-content: space-between;
						gap: 15px;
						.image_wrapper {
							background-color: #bfd9fc;
							border-radius: 4px;
							height: 32px;
							width: 32px;
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 12px;
							color: #000;
						}
						.text_list {
							display: flex;
							flex-direction: column;
							gap: 5px;
							h4 {
								font-size: 12px;
								font-weight: 500;
								line-height: 18px;
								letter-spacing: -0.025em;
								margin-bottom: 0;
							}
							p {
								font-size: 10px;
								font-weight: 500;
								line-height: 10px;
								margin-bottom: 0;
								text-align: left;
							}
						}
					}
					span {
						color: #4285f4;
						font-family: "Inter", sans-serif;
						font-size: 10px;
						font-weight: 500;
						line-height: 10px;
						text-align: left;
						background-color: #fbfbfb;
						height: 24px;
						padding: 6px;
						border-radius: 4px;
					}
				}
			}
		}
		.submit_btn {
			width: 100%;
			border-top: 1px solid #a291911a;
			border-top: 1px solid #a291911a;
			position: fixed;
			bottom: 0px;
			display: flex;
			padding: 20px;
			align-items: center;
			justify-content: flex-end;
			max-width: 480px;
			z-index: 10;
			background-color: white;
			button {
				color: white;
				font-weight: 600;
				font-size: 12px;
				height: 36px;
				border-radius: 6px;
			}
		}
	}

	form.edit_event_form .ceremonies .ceremony_input h6,
	form.create_ceremony_form .ceremonies .ceremony_input h6 {
		margin-bottom: 10px;
	}
}

.event_overview_pdf {
	h5 {
		font-size: 18px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: -0.025em;
		text-align: left;
		color: #031729;
	}
	.event_day_flow_pdf {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		margin-top: 30px;
		gap: 30px;
		textarea {
			height: 100px;
		}
		.customize_view {
			background-color: white;
			border-radius: 8px;
			padding: 20px 20px;
			font-size: 16px;
			font-weight: 500;
			line-height: 18.2px;
			letter-spacing: -0.025em;
			text-align: left;
			color: #031729;
			width: 300px;

			.form_element label {
				font-size: 14px;
			}

			.form_element.checkbox {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 10px;
				label {
					order: 2;
					margin-bottom: 0;
				}
			}

			.form_element.select select {
				font-size: 14px;
				height: 40px;
			}

			.button__group {
				button {
					font-size: 14px;
				}
			}

			.select_date_input_view {
				width: 100px;
				max-width: 100px;

				.select_date_input {
					input {
						max-width: 100px;
					}
					button.btn {
						padding: 0;
						right: 5px;
						img {
							height: 20px;
							width: 20px;
						}
					}
				}
			}

			input {
				padding: 0px 10px;
				font-size: 12px;
				height: 40px;
			}

			.event_start_time {
				width: 100%;
				.select_date_input_view {
					width: 100%;
					max-width: 100%;
					.select_date_input {
						input {
							max-width: 100%;
						}
					}
				}
			}

			textarea {
				font-size: 12px;
			}

			input::placeholder,
			textarea::placeholder {
				font-size: 12px;
			}

			.event_timeline_group {
				display: flex;
				flex-direction: column;
				gap: 10px;

				.date_group_wrapper {
					.date_group {
						width: 100%;
						justify-content: space-between;
					}
					button.add_btn {
						border: 1px solid #fcf3f3;
						border-radius: 6px;
						height: 40px;
						width: 40px;
						min-height: 40px;
					}
				}
			}
		}
	}
}

@media (max-width: 768px) {
	div.dashboard_modal.show.edit_ceremony .modal-dialog .modal-content .modal-body form.edit_event_ceremony_form .ceremony_input_group {
		flex-wrap: wrap;
	}
}

@media (max-width: 560px) {
	.dashboard_modal.show.edit_ceremony .modal-dialog .modal-content .modal-body form.edit_event_ceremony_form .custom_input_view,
	.dashboard_modal.show.edit_ceremony .modal-dialog .modal-content .modal-body form.edit_event_ceremony_form .select_date_input_view {
		width: 100%;
		min-width: 100%;
	}
}
