$navbarBg: rgb(25, 25, 26);
$primary: #cb3050;
$base_red: #e72e50;
$secondary: #fcf3f3;
$dark_red: #da334e;
$white: #fff;
$dark: #303030;
$dark_50: #e9e9e980;
$slate_gray: #404040;
$dark_gray: #888C8F;
$light_gray: #c3c3c5;
$gray_200: #828282;
$gray_50: #f7f7f7;
$alternate: #c0c6a7;
$base: #545565;
$poppins: "Poppins", sans-serif;
$bg-height: 80vh;
$font-size_sm: 0.75rem;
// mobile
$bp-mob: 320px;
// phone
$bp-phone: 500px;
$bp-lg-phone: 560px;
// tablet
$bp-tab: 768px;
$bp-tab-one-pixel-down: 767px;
// desktop
$bp-dsk: 1024px;
$bp-dsk-one-pixel-down: 1023px;
// laptop
$bp-lap: 1200px;
// fullhd
$bp-fhd: 1440px;
