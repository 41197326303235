@import "./variables.scss";

.dropdown.filter_dropdown {
	background-color: white;
	border-radius: 6px;
	height: 28px;

	button {
		background-color: white;
		padding: 4px 8px !important;
		font-size: 12px;
		color: #404040;
		border-radius: 6px;
		font-family: "Inter", sans-serif;
		svg {
			height: 12px;
			width: 12px;
			stroke: #404040;
		}
		&.dropdown_toggle {
			display: flex;
			align-items: center;
			gap: 7px;
			font-size: $font-size_sm;
			font-weight: 500;
			height: 28px;
			span.line {
				color: #e9e9e9;
				margin: 0px 3px;
			}
			span.ml-2 {
				svg {
					height: 10px;
					width: 10px;
				}
			}
		}
	}
}
